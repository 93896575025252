<template>
    <div class="pageContol studentList">
      <div class="framePage">
        <div class="framePage-title">
          <span>
            <em>当前位置：</em>
            <a href="javascript:;">考试管理</a>
            <i>></i>
            <a href="javascript:;" class="cur-a">考试档案</a>
          </span>
        </div>
        <div class="framePage-body">
          <div class="operationControl flexdc" style="align-items: flex-start">
            <div class="searchbox">
              <div title="考试名称" class="searchboxItem ci-full">
                <span class="itemLabel">考试名称:</span>
                <el-input v-model="retrievalData.examinationName" type="text" size="small" placeholder="请输入考试名称"
                  clearable />
              </div>
              <div title="职业名称" class="searchboxItem ci-full">
                <span class="itemLabel">职业名称:</span>
                <el-select v-model="retrievalData.occupationId" filterable remote clearable size="small"
                  :remote-method="getOccupation" placeholder="请至少输入两个字搜索">
                  <el-option v-for="item in occupation" :key="item.occupationId" :label="item.occupationName"
                    :value="item.occupationId">
                  </el-option>
                </el-select>
              </div>
              <div title="考试科目" class="searchboxItem ci-full">
                <span class="itemLabel">考试科目:</span>
                <el-select v-model="retrievalData.subjectCode" filterable clearable size="small" placeholder="请选择考试科目">
                  <el-option v-for="item in subjectCodeList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <!-- <el-button type="primary" class="bgc-bv" round @click="goAddEdit()">新建</el-button> -->
            </div>
          </div>
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
                :header-cell-style="tableHeader" stripe>
                <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
                <el-table-column label="考试名称" align="left" show-overflow-tooltip prop="examinationName" minWidth="180" />
                <el-table-column label="考试类型" align="left" show-overflow-tooltip prop="examType" minWidth="120">
                  <template slot-scope="scope">
                    {{ $setDictionary("EXAMINATION_EXAM_TYPE", scope.row.examType) }}
                  </template>
                </el-table-column>
                <el-table-column label="职业名称" align="left" show-overflow-tooltip prop="occupationName" minWidth="180" />
                <el-table-column label="等级" align="left" show-overflow-tooltip prop="levelCode" minWidth="100">
                  <template slot-scope="scope">
                    {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                  </template>
                </el-table-column>
                <el-table-column label="考试科目" align="left" prop="subjectCode" show-overflow-tooltip min-width="150">
                  <template slot-scope="scope">
                    {{ $setDictionary("EA_EXAM_SUBJECT_CODE", scope.row.subjectCode) }}
                  </template>
                </el-table-column>
                <el-table-column label="考试时间" align="left" show-overflow-tooltip min-width="300px">
                  <template slot-scope="scope">
                    {{ scope.row.startTime | moment }} 至 {{ scope.row.endTime | moment }}
                  </template>
                </el-table-column>
                <el-table-column label="考试人数" align="left" show-overflow-tooltip prop="peopleNum" minWidth="100" />
                <el-table-column label="考试状态" align="left" prop="examState" show-overflow-tooltip min-width="100">
                  <template slot-scope="scope">
                    {{ $setDictionary("EXAMINATION_EXAM_STATE", scope.row.examState) }}
                  </template>
                </el-table-column>
                <el-table-column label="阅卷状态" align="left" prop="scoringState" show-overflow-tooltip min-width="100">
                  <template slot-scope="scope">
                    {{ scope.row.examState === "30" ? $setDictionary("EXAMINATION_SCORING_STATE", scope.row.scoringState): "" }}
                  </template>
                </el-table-column>
                <el-table-column label="成绩发布状态" align="left" prop="publishResult" show-overflow-tooltip width="120">
                  <template slot-scope="scope">
                    {{ scope.row.publishResult ? '已发布': '未发布' }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200px" fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text" style="padding: 0px 5px" size="mini"
                      @click="downloadSummaryArchives(scope.row)" :disabled="!scope.row.publishResult">下载汇总档案</el-button>
                      <el-button type="text" style="padding: 0px 5px" size="mini"
                      @click="downloadExamPaper(scope.row)" :disabled="!scope.row.publishResult">下载考试试卷</el-button>
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
          <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        </div>
      </div>
    </div>
  </template>
  <script>
  import Empty from "@/components/Empty.vue";
  import PageNum from "@/components/PageNum.vue";
  import List from "@/mixins/List";
  import { resetKeepAlive } from "@/utils/common";
  
  import { mapGetters } from "vuex";
  export default {
    name: "examination_onlineTest_onlineTestList",
    components: {
      Empty,
      PageNum,
    },
    mixins: [List],
    data() {
      return {
        // 检索数据
        retrievalData: {
          examinationName: "", // 考试名称
          occupationId: "", // 职业名称
          subjectCode: "", // 考试科目
        },
        // 职业名称 - 下拉数据
        occupation: [],
        // 考试科目 - 下拉数据
        subjectCodeList: [],
      };
    },
    created() {
      this.dataDictionary();
    },
    watch: {},
    computed: {
      ...mapGetters({
        downloadItems: "getDownloadItems",
      }),
    },
    mounted() { },
    methods: {
      // 下载汇总档案
      downloadSummaryArchives(row){
        let parmar = {
          examinationId: row.examinationId
        };
        this.$post("/biz/examination/examineRecord/download", parmar, 3000, true, 6).then((res) => {
          if (res.status == "0") {
            console.log(res.data)
                let list = res.data;
                // list.push(res.data);
                for (let item of list) {
                  console.log(item);
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(re.message);
              }
        });

      },
      // 下载考试试卷
      downloadExamPaper(row){
        let parmar = {
          examinationId: row.examinationId
        };
        this.$post("/biz/examination/paper/download", parmar, 3000, true, 6).then((res) => {
          if (res.status == "0") {
            console.log(res.data)
                let list = res.data;
                // list.push(res.data);
                for (let item of list) {
                  console.log(item);
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(re.message);
              }
        });

      },

      


      // 获取 - 职业名称
      getOccupation(occupationName) {
        if (occupationName.trim().length >= 2) {
          this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
            .then((ret) => {
              this.occupation = ret.data
            })
            .catch((err) => {
              return;
            });
        } else {
          this.occupation = [];
        }
      },
      // 获取 - 数据字典
      dataDictionary() {
        // 考试科目
        const subjectCodeList = this.$setDictionary(
          "EA_EXAM_SUBJECT_CODE",
          "list"
        );
        const list1 = [];
        for (const key in subjectCodeList) {
          list1.push({
            value: key,
            label: subjectCodeList[key],
          });
        }
        this.subjectCodeList = list1;
      },
      // 获取 - 列表数据
      getData(pageNum = 1) {
        const params = {
          pageNum: pageNum,
          pageSize: this.pageSize,
        };
        if (this.retrievalData.examinationName) {
          params.examinationName = this.retrievalData.examinationName;
        }
        if (this.retrievalData.occupationId) {
          params.occupationId = this.retrievalData.occupationId;
        }
        if (this.retrievalData.subjectCode) {
          params.subjectCode = this.retrievalData.subjectCode;
        }
        this.doFetch({
          url: "/biz/examination/pageList",
          params,
          pageNum,
        }, true, 6);
        setTimeout(() => {
          console.log(this.tableData)
        }, 1000)
      },
    },
    beforeRouteLeave: resetKeepAlive,
  };
  </script>
  <style lang="less" scoped></style>
    